/* Fontawesome*/
$fa-font-path: '../fontawesome/';
$fontawesome: 'Font Awesome 5 Pro';

@import '../../node_modules/@fortawesome/fontawesome-pro/scss/brands.scss';
@import '../../node_modules/@fortawesome/fontawesome-pro/scss/solid.scss';
@import '../../node_modules/@fortawesome/fontawesome-pro/scss/regular.scss';
@import '../../node_modules/@fortawesome/fontawesome-pro/scss/duotone.scss';
@import '../../node_modules/@fortawesome/fontawesome-pro/scss/light.scss';
@import '../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome.scss';
@import '../../node_modules/font-awesome-animation/font-awesome-animation';


// fa-clock-o: Muss "händisch" gesetzt werden, damit das Symbol beim Date-Picker funktioniert
.fa-clock-o::before {
  font-weight: 300; //fa-light erzwingen.
  content: fa-content($fa-var-clock);
}